<template>
  <div class="avatar-uploader">
    <!-- Avatar Preview -->
    <div
      class="avatar-preview"
      :aria-label="croppedImageData ? 'Cropped Avatar' : 'Default Avatar'"
      @click="onAvatarClick"
    >
      <img v-if="croppedImageData" :src="croppedImageData.base64" alt="Cropped Avatar" />
      <div v-else class="placeholder-avatar">
        <span>Añadir foto</span>
      </div>
    </div>

    <!-- Hidden File Input -->
    <input
      type="file"
      ref="fileInput"
      @change="onFileChange"
      accept="image/*"
      aria-label="Upload Avatar"
      style="display: none"
    />

    <div v-if="false">
      <br />
      <div style="overflow-x: auto; max-width: 100px">croppedImageData {{ croppedImageData }}</div>
    </div>

    <!-- Cropper Modal -->
    <div v-if="showCropper" class="modal-overlay" role="dialog" aria-modal="true">
      <div class="modal-content">
        <vue-cropper
          ref="cropper"
          cross
          :src="image"
          :aspect-ratio="1"
          :view-mode="1"
          :background="false"
          :auto-crop-area="1"
          :zoomable="true"
          :scalable="false"
          :guides="false"
          :drag-mode="'move'"
          :min-crop-box-width="minCropBoxSize"
          :min-crop-box-height="minCropBoxSize"
          @ready="onCropperReady"
          style="width: 100%; max-width: 400px"
        />

        <!-- Cropper Controls with Vuetify Icons -->
        <div class="cropper-controls" style="display: block">
          <!-- buttons group 1 -->
          <div>
            <v-btn icon flat @click="replaceImage">
              <v-icon>mdi-camera</v-icon>
            </v-btn>
            <v-btn icon flat @click="resetZoom">
              <v-icon>mdi-restore</v-icon>
            </v-btn>

            <v-btn icon flat @click="cropImage">
              <v-icon color="secondary">mdi-content-save</v-icon>
            </v-btn>
            <v-btn icon flat @click="cancelCrop">
              <v-icon color="error">mdi-close</v-icon>
            </v-btn>
            <v-btn icon flat @click="moveUp">
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon flat @click="moveDown">
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </div>

          <!-- buttons group 2 -->
          <div>
            <v-btn icon flat @click="rotateLeft">
              <v-icon>mdi-rotate-left-variant</v-icon>
            </v-btn>
            <v-btn icon flat @click="rotateRight">
              <v-icon>mdi-rotate-right-variant</v-icon>
            </v-btn>
            <v-btn icon flat @click="zoomIn">
              <v-icon>mdi-magnify-plus</v-icon>
            </v-btn>
            <v-btn icon flat @click="zoomOut">
              <v-icon>mdi-magnify-minus</v-icon>
            </v-btn>
            <v-btn icon flat @click="moveLeft">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-btn icon flat @click="moveRight">
              <v-icon>mdi-arrow-right</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="modal-actions" v-if="false">
          <v-btn flat @click="cancelCrop" color="error">Cancel</v-btn>
          <v-btn flat color="secondary" @click="cropImage">Crop & Save</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
// No need to import Vuetify components since it's globally available..

const fetchImage = async (url) => {
  try {
    // Fetch the image from the given URL
    const response = await fetch(url)

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    // Extract content type from response headers
    const contentType = response.headers.get('Content-Type')
    const mimeType = contentType.split(';')[0] // e.g., "image/jpeg"

    // Extract the image data as a blob
    const blob = await response.blob()

    // Convert the blob to a base64 data URL
    const reader = new FileReader()
    reader.readAsDataURL(blob)

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        //const base64String = reader.result.split(',')[1] // Strip out "data:*/*;base64," prefix
        const base64String = reader.result //

        // Extract filename from URL (optional, you may have a better way to get filename)
        const filename = 'just-an-avatar-image' //url.split('/').pop()

        // Create JSON object
        const imageJson = {
          filename: filename,
          mimetype: mimeType,
          base64: base64String,
        }
        console.log('imageJson', imageJson)
        resolve(imageJson)
      }

      reader.onerror = () => {
        reject('Failed to read the image data as base64')
      }
    })
  } catch (error) {
    console.error('Error fetching the image:', error)
    return null
  }
}

export default {
  name: 'AvatarUploader',
  components: {
    VueCropper,
  },
  props: {
    initialImage: {
      type: String,
      default: '', // Default to an empty string if no URL is provided
    },
  },
  emits: ['onChanged'], // Emit an event when the image changes
  setup(props, { emit }) {
    const image = ref(null)
    const croppedImageData = ref(null) // Stores the object with mimetype, base64, and filename
    const cropper = ref(null)
    const showCropper = ref(false)

    const minCropBoxSize = 100 // Minimum crop box size in pixels

    const fileInput = ref(null)

    // Additional variables to store filename and mimetype
    const fileName = ref('')
    const mimeType = ref('')

    // Handle the initial image if provided
    if (props.initialImage) {
      fetchImage(props.initialImage).then((x) => {
        //this approach is more CORS friendly for some reason
        image.value = x.base64
        croppedImageData.value = x
      })
    }

    watch(
      () => props.initialImage,
      (newValue) => {
        if (newValue) {
          fetchImage(newValue).then((x) => {
            //this approach is more CORS friendly for some reason
            image.value = x.base64
            croppedImageData.value = x
          })
        } else {
          resetCropper()
        }
      },
      { deep: true }
    )

    const onAvatarClick = () => {
      resetCropper()
      if (croppedImageData.value) {
        // Reopen the cropper with the current cropped image
        console.log('croppedImageData', croppedImageData)
        image.value = ''
        image.value = croppedImageData.value.base64
        showCropper.value = true
      } else {
        // Trigger the file input click
        fileInput.value.click()
      }
    }

    const replaceImage = () => {
      // Trigger the file input click to replace the image
      fileInput.value.click()
    }

    const onFileChange = (event) => {
      resetCropper()
      const file = event.target.files?.[0]
      if (!file) return

      // Validate file type
      if (!file.type.startsWith('image/')) {
        showAlert('Please select a valid image file.')
        return
      }

      // Validate file size (max 5MB)
      const maxSize = 10 * 1024 * 1024
      if (file.size > maxSize) {
        showAlert('The selected file is too large. Please select a file smaller than 5MB.')
        return
      }

      // Compress the image to avoid issues with large files
      compressImage(file)
        .then((compressedBase64) => {
          image.value = compressedBase64
          showCropper.value = true
        })
        .catch(() => showAlert('Error processing image.'))

      // Store the filename and mimetype
      fileName.value = file.name
      mimeType.value = file.type

      const reader = new FileReader()
      reader.onload = (e) => {
        image.value = ''
        image.value = e.target.result
        showCropper.value = true
        // Clear the file input value to allow selecting the same file again if needed
        event.target.value = ''
      }
      reader.onerror = () => {
        showAlert('Error reading file.')
      }
      reader.readAsDataURL(file)
    }

    const compressImage = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          const img = new Image()
          img.src = e.target.result
          img.onload = () => {
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')

            const MAX_WIDTH = 800
            const scaleFactor = Math.min(1, MAX_WIDTH / img.width)

            canvas.width = img.width * scaleFactor
            canvas.height = img.height * scaleFactor
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

            try {
              const compressedBase64 = canvas.toDataURL(mimeType.value, 0.7)
              resolve(compressedBase64)
            } catch (error) {
              reject(error)
            }
          }
          img.onerror = reject
        }
        reader.onerror = reject
        reader.readAsDataURL(file)
      })
    }

    const cropImage = () => {
      try {
        if (cropper.value && cropper.value.cropper) {
          // Obtén el canvas recortado desde la instancia de Cropper.js
          const canvas = cropper.value.cropper.getCroppedCanvas()

          // Asegura que la imagen de salida tenga la resolución deseada
          const outputSize = 200 // Tamaño deseado del avatar
          const outputCanvas = document.createElement('canvas')
          outputCanvas.width = outputSize
          outputCanvas.height = outputSize

          const ctx = outputCanvas.getContext('2d')

          // Dibuja la imagen recortada en el canvas de salida, escalándola
          ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, outputSize, outputSize)

          // Obtén los datos base64
          const base64Data = outputCanvas.toDataURL(mimeType.value)

          // Establece el objeto croppedImageData
          croppedImageData.value = {
            mimetype: mimeType.value,
            base64: base64Data,
            filename: fileName.value,
          }

          // Emite el evento 'onChanged' con los datos de la imagen recortada
          emit('onChanged', { ...croppedImageData.value })

          resetCropper()
        } else {
          showAlert('El cropper no está listo.')
        }
      } catch (error) {
        showAlert('Ocurrió un error al recortar la imagen.')
        console.error(error)
      }
    }

    const cancelCrop = () => {
      resetCropper()
    }

    const resetCropper = () => {
      // Keep the current image if editing existing avatar
      showCropper.value = false
    }

    const rotateLeft = () => {
      cropper.value?.cropper.rotate(-90)
    }

    const rotateRight = () => {
      cropper.value?.cropper.rotate(90)
    }

    const zoomIn = () => {
      cropper.value?.cropper.zoom(0.1)
    }

    const zoomOut = () => {
      cropper.value?.cropper.zoom(-0.1)
    }

    const resetZoom = () => {
      cropper.value?.cropper.reset()
    }

    const moveLeft = () => {
      cropper.value?.cropper.move(10, 0)
    }

    const moveRight = () => {
      cropper.value?.cropper.move(-10, 0)
    }

    const moveUp = () => {
      cropper.value?.cropper.move(0, 10)
    }

    const moveDown = () => {
      cropper.value?.cropper.move(0, -10)
    }

    const onCropperReady = () => {
      // Automatically zoom to fit the minimum crop box size
      const imageData = cropper.value.cropper.getImageData()
      const scaleFactor = Math.max(
        minCropBoxSize / imageData.naturalWidth,
        minCropBoxSize / imageData.naturalHeight
      )

      if (scaleFactor > 1) {
        cropper.value.cropper.zoomTo(scaleFactor)
      }
    }

    const showAlert = (message) => {
      // Replace this with your preferred notification system
      alert(message)
    }

    // Include fileName and mimeType in the returned object
    return {
      image,
      croppedImageData,
      onFileChange,
      compressImage,
      cropImage,
      cancelCrop,
      showCropper,
      cropper,
      rotateLeft,
      rotateRight,
      zoomIn,
      zoomOut,
      resetZoom,
      moveLeft,
      moveRight,
      moveUp,
      moveDown,
      minCropBoxSize,
      onCropperReady,
      onAvatarClick,
      replaceImage,
      fileInput,
      fileName, // Added
      mimeType, // Added
    }
  },
}
</script>

<style scoped>
.avatar-uploader {
  text-align: center;
}

.avatar-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.avatar-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e0e0e0;
  font-size: 14px;
  color: #8a8a8a;
}

.placeholder-avatar span {
  pointer-events: none;
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  width: 90%;
  max-width: 500px;
}

/* Make the modal full-screen on small devices */
@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    overflow-y: auto;
  }
}

/* Ensure controls remain visible */
.cropper-controls,
.modal-actions {
  margin-top: 10px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

input[type='file'] {
  display: none;
}
</style>
OrganizationAccountAccessRole
